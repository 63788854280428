import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { WhiteBoxRegistration as WhiteBox, Price } from '../components';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { NewRootUserDto, NewUserDto } from '@docpack/shared/dtos';
import { useRegisterUser } from '../data-access/register-user.hook';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useLocation } from 'react-router-dom';
import { Box, Link, Tooltip, Container } from '@mui/material';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';
import Logo from '../components/not-logged-in-shell/logo.png';
import { RegisterForm } from '../components/forms/register-form';
import {
  AlertController,
  useFormMethods,
  useHandleClose,
  useMessagePopup,
  WhiteBoxModalContainer,
} from '@docpack/frontend/core-components';
import { BorderStyle } from '@mui/icons-material';

const sizeFooter = 40;

export function RegisterPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const params = new URLSearchParams(location.search);
  const resolver = classValidatorResolver(NewRootUserDto);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<NewRootUserDto>({ resolver });
  const { error, registerNewUser } = useRegisterUser();

  const plan: string = (params.get('plan') || 'one').toString();

  const formMethods = useFormMethods(NewRootUserDto, true);
  const onSubmit = (data: NewRootUserDto) => {
    return registerNewUser(data, plan)
      .then((res) => {
        navigate(`check-code/${res?.user_uuid}`);
      })
      .catch((error) => {
        console.error('Error fetching data: ', error);
      });
  };
  return (
    <>
      <Grid
        justifyContent={'center'}
        paddingBottom={10}
        container
        columns={2}
        gap={10}
      >
        <Grid
          item
          sx={{
            p: 4,
            width: '360px',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'gray.main',
            borderRadius: '20px',
          }}
        >
          <Box component="div">
            <RegisterForm
              formMethods={formMethods}
              onSubmit={onSubmit}
              onBack={() => navigate('/')}
            />
          </Box>
        </Grid>
        <Grid item maxWidth={500}>
          {<Price value={plan} />}
        </Grid>
      </Grid>
      <FooterContainer
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Link>
          <img src={Logo} width="200" />
        </Link>
        <Typography>
          DOCpack Via del Gambero, 36/38 - 41124 Modena info@docpack.it +39 059
          822109{' '}
        </Typography>
        <hr />
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={2}
          style={{
            fontSize: theme.typography.overline.fontSize,
            lineHeight: theme.typography.overline.lineHeight,
            fontWeight: theme.typography.overline.fontSize,
          }}
        >
          <Box sx={{ float: 'left' }}>
            Copyright 2023 DOC Design Srl. P.IVA 03264720362 - REA MO - 372653
          </Box>

          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            sx={{ float: 'right' }}
          >
            <Link
              color={theme.palette.white.main}
              target="_blank"
              rel="noreferrer"
              underline="hover"
              href="https://docpack.it/privacy-policy/"
            >
              Informativa Privacy
            </Link>
            <Box>|</Box>
            <Link
              color={theme.palette.white.main}
              target="_blank"
              rel="noreferrer"
              underline="hover"
              href="https://docpack.it/condizioni-duso/"
            >
              Termini e Condizioni
            </Link>
          </Stack>
        </Stack>
        <Box data-portal="projects-count"></Box>
      </FooterContainer>
    </>
  );
}

const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  bottom: 0;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  z-index: 9;
  padding: 0 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  @media (min-width: 600px) {
    padding: 0 24px;
  }
`;

export default RegisterPage;
