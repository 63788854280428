import React from 'react';
import { useLogin } from '../hooks';
import { Location, Navigate, useLocation } from 'react-router-dom';

export interface RequiredAuthProps {
  redirectUri: string;
}

export interface StateRedirectAuth {
  from?: Location;
}

export function RequiredAuth(
  props: React.PropsWithChildren<RequiredAuthProps>
) {
  const { loading, loggedIn } = useLogin();
  const location = useLocation();

  if (!loading && !loggedIn) {
    const state: StateRedirectAuth = { from: location };
    return <Navigate to={props.redirectUri} state={state} />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{props.children}</>;
}
