import {
  Box,
  Container,
  Checkbox,
  Typography,
  FormControlLabel,
  Stack,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';

interface PricePlan {
  value: string;
}

export interface Configuration {
  title: string;
  price: number;
  description: string;
  features: string[];
}

export function Price(plan: PricePlan) {
  const theme = useTheme();
  const plans = ['one', 'team', 'business'];
  let { value } = plan;
  if (!plans.includes(value)) value = 'one';

  const one: Configuration = {
    title: 'One',
    price: 180.0,
    description:
      'Il piano ideale per un singolo utente.\nTi permette di archiviare fino a 100 progetti.',
    features: [
      '1 Account',
      '100 Gb di archiviazione',
      '100 progetti',
      'Marchi',
      'Categorie e sottocategorie',
      'Fornitori',
      'Report attività',
    ],
  };
  const team: Configuration = {
    title: 'Team',
    price: 240.0,
    description:
      'Tutte le funzionalità del piano One con la possibilità di aggiungere fino a 5 collaboratori alla piattaforma.',
    features: [
      '5 Account',
      '500 Gb di archiviazione',
      '300 progetti',
      'Tutte le funzionalità del piano Basic',
    ],
  };
  const business: Configuration = {
    title: 'Business',
    price: 500.0,
    description:
      'Ideale per una gestione del progetto a un team esteso anche a società esterne.',
    features: [
      '40 Account',
      '2 Tb di spazio di archiviazione',
      '500 progetti',
      'Possibilità di associare fino a 5 marchi a società esterne',
      'Tutte le funzionalità del piano Basic',
    ],
  };

  const configurations: any = {
    one: one,
    team: team,
    business: business,
  };

  return (
    <Container>
      <Typography variant="microtitle" component="h5" mb={2}>
        {configurations[value].title}
      </Typography>
      <Typography variant="h3" component="span">
        €0
        <Typography variant="subtitle" component="span" fontWeight={500}>
          /per 30 giorni*{' '}
          <Box
            sx={{ display: 'inline', fontSize: 'small', fontWeight: 'normal' }}
          >
            (Poi €{configurations[value].price} al mese)
          </Box>
        </Typography>
      </Typography>
      <Typography sx={{ fontSize: 'small' }}>
        *Non ti verranno chiesti i dati della carta di credito per iniziare la
        prova gratuita.
      </Typography>
      <Typography variant="body1" sx={{ paddingTop: '18px' }}>
        {configurations[value].description}
      </Typography>
      <Stack>
        {configurations[value].features.map((val: string) => (
          <FormControlLabel
            control={<Checkbox color="warning" checked={true} value={true} />}
            label={val}
          />
        ))}
      </Stack>
    </Container>
  );
}

export default Price;
